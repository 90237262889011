declare const dataSource : string;

interface IParam {
    label : string,
    value : string,
}

interface IData {
    icon : string,
    name : string,
    description : {
        label : string,
        value : string,
        buttonMore : string,
        buttonLess : string,
    },
    categories : string[],
    additional : {
        label : string,
        adsText : string,
        updatedAt : IParam,
        version : IParam,
        filesize : IParam,
        ageRate : IParam,
        installs : IParam,
        developer : IParam,
    },
    install : {
        buttonLabel : string,
        wishlistButtonLabel : string,
    },
    rating : {
        ratingLabel : string,
        votersLabel : string,
        rate : number,
        votersCount : number,
        rate1Percent : number,
        rate2Percent : number,
        rate3Percent : number,
        rate4Percent : number,
        rate5Percent : number,
        bestChoiceText : string,
    },
    screenshots : string[],
    changelog : IParam,
    reviews : {
        label : string,
        comments : {
            username : string,
            avatar : string,
            date : string,
            rating : number,
            comment : string,
            feedback ?: {
                date : string,
                comment : string,
            },
        }[],
    },
}

const test : IData = {
    icon: '/static/6.png',
    name: 'Name',
    description: {
        label: 'О приложении',
        value: '<div>Очередное приложения для Android</div><div>Очередное приложения для Android</div><div>Очередное приложения для Android</div><div>Очередное приложения для Android</div>',
        buttonMore: 'Подробнее...',
        buttonLess: 'Меньше',
    },
    categories: [
        'SuperSoft',
        'Игры',
    ],
    additional: {
        label: 'Дополнительная информация',
        adsText: 'Без рекламы',
        updatedAt: {
            label: 'Обновлено',
            value: '3 сентяря 2021',
        },
        version: {
            label: 'Текущая версия',
            value: '1.0.0',
        },
        filesize: {
            label: 'Размер',
            value: '12.1 MB',
        },
        ageRate: {
            label: 'Возраст',
            value: '18+',
        },
        installs: {
            label: 'Установок',
            value: '100500+',
        },
        developer: {
            label: 'Разработчик',
            value: 'SuperSoft',
        },
    },
    install: {
        buttonLabel: 'Установить',
        wishlistButtonLabel: 'Добавить в желаемое',
    },
    rating: {
        ratingLabel: 'Рейтинг',
        votersLabel: 'Всего',
        rate: 4.9,
        votersCount: 1256,
        rate1Percent: 0,
        rate2Percent: 10,
        rate3Percent: 0,
        rate4Percent: 20,
        rate5Percent: 70,
        bestChoiceText: 'Выбор редакции',
    },
    screenshots: [
        '/static/6.1.jpg',
        '/static/6.2.jpg',
        '/static/6.3.jpg',
        '/static/6.4.jpg',
    ],
    changelog: {
        label: 'Что нового',
        value: 'Разработчики исправили все баги и добавили много нужных штук',
    },
    reviews: {
        label: 'Оценки и отзывы',
        comments: [{
            username: 'Martin',
            avatar: '/static/user1.avif',
            date: '25.03.24',
            rating: 5,
            comment: 'Отличное приложение!',
            feedback: {
                date: '26.03.24',
                comment: 'Спасибо за ваш отзыв!',
            },
        }, {
            username: 'Luter',
            avatar: '/static/user2.avif',
            date: '25.03.24',
            rating: 3,
            comment: 'Отличное приложение!',
        }, {
            username: 'King',
            avatar: '',
            date: '25.03.24',
            rating: 5,
            comment: 'Отличное приложение!',
            feedback: {
                date: '26.03.24',
                comment: 'Спасибо за ваш отзыв!',
            },
        }],
    },
};
// console.log(JSON.stringify(test));

let deferredPrompt;
const installButtons = document.querySelectorAll('.install');

window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredPrompt = e;
});

installButtons.forEach(btn => btn.addEventListener('click', () => {
    if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then(res => {
            if (res.outcome === 'accepted') {
                console.log('Accepted');
            } else {
                console.log('Refused');
            }
            deferredPrompt = null;
        });
    }
}));

document.addEventListener('DOMContentLoaded', () => {
    document.querySelector('#read-more').addEventListener('click', (e : Event) => {
        const article : Element = document.querySelector('#description > article');
        const button = e.target as HTMLElement;
        if(article.classList.contains('show')) {
            article.classList.remove('show');
            button.innerHTML = button.dataset.more;
        } else {
            article.classList.add('show');
            button.innerHTML = button.dataset.less;
        }
    });

    // insert data
    const data : IData = JSON.parse(dataSource);
    // const data : IData = test;

    // name & title
    document.querySelector('title').innerText = data.name;
    document.querySelector('#app-name').innerHTML = data.name;
    // category
    const appCategory = document.querySelector('#app-category');
    for(let i = 0; i < data.categories.length; i++) {
        const li = document.createElement('li');
        li.append(data.categories[i]);
        appCategory.append(li);
    }
    // logo
    const logo = document.createElement('img');
    logo.src = data.icon;
    document.querySelector('#logo').appendChild(logo);
    // screenshots
    const appScreenshots = document.querySelector('#app-screenshots');
    for(let i = 0; i < data.screenshots.length; i++) {
        const div = document.createElement('div');
        const img = document.createElement('img');
        img.src = data.screenshots[i];
        div.append(img);
        appScreenshots.append(div);
    }
    // rating
    document.querySelector('#app-choice-text').textContent = data.rating.bestChoiceText;
    document.querySelectorAll('.app-voters').forEach(e => e.textContent = data.rating.votersCount.toString());
    document.querySelectorAll('.app-voters-label').forEach(e => e.textContent = data.rating.votersLabel);
    document.querySelectorAll('.app-rating-label').forEach(e => e.textContent = data.rating.ratingLabel);
    document.querySelectorAll('.app-rating').forEach(e => e.textContent = data.rating.rate.toString());
    document.querySelectorAll('.app-rating-stars').forEach(e => {
        const count = Math.min(Math.round(data.rating.rate), 5);
        for(let i = 0; i < count; i++) {
            const star = document.createElement('li');
            e.append(star);
        }
    });
    document.querySelector('#chart-line-5').setAttribute('style', `width: ${data.rating.rate5Percent}%`);
    document.querySelector('#chart-line-4').setAttribute('style', `width: ${data.rating.rate4Percent}%`);
    document.querySelector('#chart-line-3').setAttribute('style', `width: ${data.rating.rate3Percent}%`);
    document.querySelector('#chart-line-2').setAttribute('style', `width: ${data.rating.rate2Percent}%`);
    document.querySelector('#chart-line-1').setAttribute('style', `width: ${data.rating.rate1Percent}%`);
    // install
    document.querySelectorAll('.wishlist').forEach(e => e.textContent = data.install.wishlistButtonLabel);
    document.querySelectorAll('.install').forEach(e => e.textContent = data.install.buttonLabel);
    // description
    document.querySelector('#description header').textContent = data.description.label;
    document.querySelector('#description article').innerHTML = data.description.value;
    document.querySelector('#read-more').textContent = data.description.buttonMore;
    document.querySelector('#read-more').setAttribute('data-more', data.description.buttonMore);
    document.querySelector('#read-more').setAttribute('data-less', data.description.buttonLess);
    // changelog
    document.querySelector('#changelog > header').textContent = data.changelog.label;
    document.querySelector('#changelog > article').innerHTML = data.changelog.value;
    // additional info
    document.querySelector('#footer > header').textContent = data.additional.label;
    document.querySelector('#app-ads').textContent = data.additional.adsText;
    document.querySelectorAll('.app-updated-at-label').forEach(e => e.textContent = data.additional.updatedAt.label);
    document.querySelectorAll('.app-updated-at-value').forEach(e => e.textContent = data.additional.updatedAt.value);
    document.querySelectorAll('.app-version-label').forEach(e => e.textContent = data.additional.version.label);
    document.querySelectorAll('.app-version-value').forEach(e => e.textContent = data.additional.version.value);
    document.querySelectorAll('.app-version-label').forEach(e => e.textContent = data.additional.version.label);
    document.querySelectorAll('.app-version-value').forEach(e => e.textContent = data.additional.version.value);
    document.querySelectorAll('.app-filesize-label').forEach(e => e.textContent = data.additional.filesize.label);
    document.querySelectorAll('.app-filesize-value').forEach(e => e.textContent = data.additional.filesize.value);
    document.querySelectorAll('.app-age-rate-value').forEach(e => e.textContent = data.additional.ageRate.value);
    document.querySelectorAll('.app-age-rate-label').forEach(e => e.textContent = data.additional.ageRate.label);
    document.querySelectorAll('.app-install-count-label').forEach(e => e.textContent = data.additional.installs.label);
    document.querySelectorAll('.app-install-count-value').forEach(e => e.textContent = data.additional.installs.value);
    document.querySelectorAll('.app-developer-label').forEach(e => e.textContent = data.additional.developer.label);
    document.querySelectorAll('.app-developer-value').forEach(e => e.textContent = data.additional.developer.value);
    // reviews
    document.querySelector('#reviews > header').textContent = data.reviews.label;
    const comments = document.querySelector('#comments');
    for(let i = 0; i < data.reviews.comments.length; i++) {
        const item = data.reviews.comments[i];
        const block = document.querySelector('#dummy-comment').cloneNode(true) as HTMLElement;
        block.removeAttribute('id');
        if(item.avatar) {
            const avatar = document.createElement('img');
            avatar.setAttribute('src', item.avatar);
            block.querySelector('.avatar').append(avatar);
        }
        document.querySelector('#comments').appendChild(block);
        block.querySelector('.name').textContent = item.username;
        block.querySelector('.date').textContent = item.date;
        const rating = block.querySelector('.rating-stars');
        const count = Math.min(Math.round(item.rating), 5);
        for(let i = 0; i < count; i++) {
            const star = document.createElement('li');
            rating.append(star);
        }
        block.querySelector('.comment-text').innerHTML = item.comment;
        if(item.feedback) {
            block.querySelector('.comment-feedback .name').textContent = data.additional.developer.value;
            block.querySelector('.comment-feedback .date').textContent = item.feedback.date;
            block.querySelector('.comment-feedback-text').textContent = item.feedback.comment;
        } else block.querySelector('.comment-feedback').remove();
        comments.append(block);
    }
    document.querySelector('#dummy-comment').remove();
});